import { useRouter } from "next/router";
import { FluentResource } from "@fluent/bundle";

import KButton from "@kikoff/components/src/v1/buttons/KButton";

import { sputter } from "@util/analytics";
import { newReactLocalization } from "@util/l10n";

import member2 from "./members/member_1.png";
import member3 from "./members/member_2.png";
import member1 from "./members/member_3.png";
import member6 from "./members/member_4.png";
import member4 from "./members/member_5.png";
import member5 from "./members/member_6.png";

import styles from "./styles.module.scss";

const members = [member1, member2, member3, member4, member5, member6];

const RESOURCES = {
  en: new FluentResource(`main-copy = We’ve helped members increase credit scores by a total of 9.6M points
get-started = Get started
`),
  es: new FluentResource(`main-copy = Hemos ayudado a los miembros a aumentar los puntajes de crédito en un total de 9,6 millones de puntos
get-started = Empezar
`),
};

function Member({ src }) {
  return (
    <div className={styles["member"]}>
      <img src={src} />
    </div>
  );
}

function MembersHelped() {
  const router = useRouter();

  const l10n = newReactLocalization(RESOURCES);

  return (
    <section className={styles["members-helped-section"]}>
      <div className={styles["members"]}>
        {members.map((memberImg) => (
          <Member key={memberImg.src} src={memberImg.src} />
        ))}
      </div>
      <div className={`text:heading-3++ ${styles["main-copy"]}`}>
        {l10n.getString("main-copy")}
        <sup>3</sup>
      </div>
      <div className={styles["button-wrapper"]}>
        <KButton
          className={styles["get-started-cta"]}
          onClick={() => {
            sputter("members helped: clicked desktop cta");
            router.push("/onboarding");
          }}
          size="standard"
          variant="primary"
        >
          <span className="color:white">{l10n.getString("get-started")}</span>
        </KButton>
      </div>
    </section>
  );
}

export default MembersHelped;
