import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";

import KButton from "@kikoff/components/src/v1/buttons/KButton";
import useMediaPredicate from "@kikoff/hooks/src/useMediaPredicate";
import { cls, combineClasses } from "@kikoff/utils/src/string";

import {
  EIGHTY_PERCENT_OFF,
  FREE_BASIC_DISCOUNTED_PREMIUM,
} from "@constant/promos";
import { selectIntroPromo } from "@feature/user";
import { useIntroPromoPoller } from "@src/hooks/promos";
import { track } from "@util/analytics";
import { l10nResources } from "@util/l10n";

import styles from "./styles.module.scss";

const l10n = l10nResources({
  en: `intro-test-header = FREE BASIC PLAN OFFER
intro-test-body = Sign up today and get your first month FREE
eighty_percent_body = Sign up today and get 80% off your first month
redeem-offer = Redeem offer
`,
  es: `intro-test-header = OFERTA PLAN BÁSICO GRATIS
intro-test-body = Regístrate hoy y obtén tu primer mes GRATIS
eighty-percent_body = Regístrate hoy y obtén un 80% de descuento en tu primer mes
redeem-offer = Canjear la oferta
`,
});

const IntroPromoBanner = () => {
  useIntroPromoPoller();

  const isMobile = useMediaPredicate("(max-width: 768px)");

  const router = useRouter();
  const promo = useSelector(selectIntroPromo());

  useEffect(() => {
    if (promo) {
      track.impression("Homepage - Intro Promo Banner", {
        promo: promo.name,
      });
    }
  }, []);

  if (!promo) {
    return null;
  }

  return (
    <div
      className={combineClasses(
        styles["intro-test-background"],
        promo && styles["show-banner"]
      )}
    >
      <div className={styles["content-wrapper"]}>
        <div className={styles["text-wrapper"]}>
          {promo.name === FREE_BASIC_DISCOUNTED_PREMIUM && (
            <span
              className={cls(
                "mr-1 color:container",
                isMobile ? "text:mini++" : "text:large++"
              )}
            >
              {l10n.getString("intro-test-header")}:
            </span>
          )}
          <span
            className={cls(
              isMobile ? "text:small+" : "text:large+",
              "color:container"
            )}
          >
            {promo.name === EIGHTY_PERCENT_OFF
              ? l10n.getString("eighty_percent_body")
              : l10n.getString("intro-test-body")}
            *
          </span>
        </div>
        <KButton
          size="small"
          variant="container"
          className={styles["redeem-offer-button"]}
          onClick={() => {
            track.tap("Homepage - Intro Promo Banner - Redeem offer", {
              promo: promo.name,
            });
            router.push("/onboarding");
          }}
        >
          {l10n.getString("redeem-offer")}
        </KButton>
      </div>
    </div>
  );
};

export default IntroPromoBanner;
